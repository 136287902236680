export type LoanType = "secured" | "unsecured";
export type PaymentFrequencyType = "monthly" | "fortnightly" | "yearly";

export type ApplicationStatus =
  | "incomplete"
  | "in progress"
  | "approved"
  | "declined"
  | "closed"
  | "expired";

export enum LoanStatuses {
  WRITTEN_OFF = "Direct Money\\Write-Off",
  CURRENT = "Direct Money\\Current",
  ARREARS = "Direct Money\\Arrears",
  DISCHARGED = "Direct Money\\Loan Discharged",
  PART_9_AGREEMENT = "Direct Money\\Part 9 Agreement",
}

export const WRITTEN_OFF_STATUSES: string[] = [
  LoanStatuses.WRITTEN_OFF,
  LoanStatuses.PART_9_AGREEMENT,
];
