import { classFormat } from "@wisr/common";
import { Action } from "@wisr/react-ui";
import { AxiosPromise } from "axios";
import React, { useState } from "react";
import axios from "../../../../shared/axios.utils";
import { downloadFile } from "../download.utils";
import downloadStyle from "./button-download.scss";

interface RequestData {
  [key: string]: any;
  fileDisplayName: string;
}

interface Props {
  apiLink: string;
  defaultState?: string;
  data?: RequestData;
  customText?: boolean;
  type?: "GET" | "POST";
  onClick?: () => void;
}

type DownloadState = "Download" | "Downloading" | "Downloaded" | "Error";

export const ButtonDownloadFile: React.FC<Props> = ({
  apiLink,
  data,
  defaultState,
  customText = false,
  children,
  type = "GET",
  onClick,
}) => {
  const [downloadState, setDownloadState] = useState(
    defaultState || ("Download" as DownloadState)
  );
  const downloadFileBlob = (api: string) => {
    let axiosCall: AxiosPromise;
    if (type === "GET") {
      axiosCall = axios.get(api, {
        params: {
          ...data,
        },
        responseType: "blob",
      });
    } else {
      axiosCall = axios.post(api, data, { responseType: "blob" });
    }

    axiosCall
      .then((resp) => {
        setDownloadState("Downloading");
        return downloadFile("", data?.fileDisplayName, resp.data);
      })
      .catch((error) => {
        console.error(error);
        setDownloadState("Error");
        setTimeout(() => {
          setDownloadState("Download");
        }, 4000);
      });
  };
  return (
    <div className={`item download ${classFormat(downloadState)}`}>
      <style jsx>{downloadStyle}</style>

      <Action size="small">
        <button
          type="button"
          onClick={() => {
            downloadFileBlob(apiLink);
            if (onClick) {
              onClick();
            }
          }}
        >
          {customText ? (
            children
          ) : (
            <>
              <span className={"icon"} /> {downloadState}
            </>
          )}
        </button>
      </Action>
    </div>
  );
};
