import { isDefined, toCurrency } from "@wisr/common";
import { Button } from "@wisr/react-ui";
import { Link } from "gatsby";
import React from "react";
import {
  analyticsClickEvent,
  analyticsLoadLoanWidgetEvent,
} from "../../shared/analytics/analytics.utils";
import loanStyle from "../../widgets/borrowing/personal-loan/personal-loan.scss";
import { BadgeComponent } from "../../shared/badge/badge.component";
import {
  HighlightComponent,
  HighlightItem,
} from "../../widgets/shared/highlight/highlight.component";
import { WidgetComponent } from "../../widgets/widget.component";
import { LoanDetails } from "../details/loan-details";
import { LoanProps } from "../loans";
import { LoanStatements } from "../statements/loan-statements";
import style from "./discharged-loan.scss";
import { extractLoanEventData } from "../../widgets/borrowing/personal-loan/personal-loan-borrowing-widget.utils";

export const DischargedLoan: React.FC<LoanProps> = ({
  variation,
  loan,
  user,
  customer,
}) => {
  const isHome = variation === "home";

  const loanInfo: HighlightItem[] = [
    {
      description: "Loan balance",
      title: isDefined(loan.currentBalance)
        ? toCurrency(loan.currentBalance)
        : "",
      type: "full",
      id: "loan-balance",
    },
  ];

  React.useEffect(() => {
    analyticsLoadLoanWidgetEvent("Loan", extractLoanEventData(loan));
  }, [loan]);

  return (
    <WidgetComponent widgetName="personal-loan" widgetType="borrow">
      <style jsx>{loanStyle}</style>
      <style jsx>{style}</style>
      <div className={`widget-title ${isHome ? "is-home-title" : ""}`}>
        <h1>Loan ID {loan.loanId}</h1>
        <BadgeComponent badge="other">Closed</BadgeComponent>
      </div>
      {isHome && (
        <div className="widget-subtitle">
          <p>
            {isDefined(loan.originalLoanAmount)
              ? toCurrency(loan.originalLoanAmount)
              : ""}
          </p>
        </div>
      )}
      <section className="widget-data">
        <div className={`loan-main ${isHome ? "home-widget" : ""}`}>
          <div className="grid">
            <HighlightComponent list={loanInfo} />
          </div>
          <div className="cta">
            {!isHome && customer && (
              <LoanStatements user={user} customer={customer} loan={loan} />
            )}
            {isHome && (
              <Button button="secondary" contrast>
                <Link
                  to={`/borrowing/loans?loanId=${loan.loanId}`}
                  onClick={() => analyticsClickEvent("View loan details")}
                >
                  View loan details
                </Link>
              </Button>
            )}
          </div>
        </div>
        {!isHome && <LoanDetails loan={loan} />}
      </section>
    </WidgetComponent>
  );
};
