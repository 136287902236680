import { LoanEventData } from "../../../types/analytics";
import {
  LoanStatuses,
  WRITTEN_OFF_STATUSES,
} from "./personal-loan-borrowing.constants";
import { PersonalLoan } from "../../../types/personal-loan";

export const isDischargedLoan = (loan?: PersonalLoan) => {
  if (loan?.status) {
    return loan.status === LoanStatuses.DISCHARGED;
  }
  return false;
};

export const shouldTreatAsInArrears = (loan: PersonalLoan) => {
  const loanStatus = loan.status;
  if (!loan.totalArrearsAmount || !loanStatus) {
    return false;
  }
  return (
    loan.totalArrearsAmount > 0 &&
    (WRITTEN_OFF_STATUSES.includes(loanStatus) ||
      loan.status === LoanStatuses.ARREARS)
  );
};

export const extractLoanEventData = (loan: PersonalLoan): LoanEventData => {
  return {
    "Loan id": loan.loanId,
    "Loan status": loan.status?.replace("Direct Money\\", ""),
  };
};

export const trimLoanStatus = (loanStatus?: string | null) => {
  if (loanStatus) {
    return loanStatus.replace("Direct Money\\", "");
  }
  return "";
};
