import { capitalise, isDefined, toCurrency, toRate } from "@wisr/common";
import { Accordion } from "@wisr/react-ui";
import React from "react";
import { stringToDate } from "../../shared/date.utils";
import {
  HighlightListComponent,
  HighlightListItem,
} from "../../widgets/shared/highlight/list-highlight.component";

import style from "./loan-details.scss";
import { PersonalLoan } from "../../types/personal-loan";

export const LoanDetails: React.FC<{ loan?: PersonalLoan }> = ({ loan }) => {
  if (!isDefined(loan)) {
    return null;
  }
  const loanDetails = convertLoanDetails(loan);
  return (
    <div>
      <style jsx>{style}</style>
      <hr className="divider" />
      <section className="loan-details">
        <Accordion title="Loan details" alignArrow="right" startOpen>
          <HighlightListComponent list={loanDetails} />
        </Accordion>
      </section>
    </div>
  );
};

const convertLoanDetails = (loan: PersonalLoan): HighlightListItem[] => [
  {
    description: "Loan amount",
    value: loan.originalLoanAmount?.toString()
      ? toCurrency(loan.originalLoanAmount, true)
      : "",
  },
  {
    description: "Interest rate",
    value: loan.interestRate?.toString() ? toRate(loan.interestRate) : "",
  },
  {
    description: "Start date",
    value: loan.settlementDate ? stringToDate(loan.settlementDate, "full") : "",
  },
  {
    description: "Loan purpose",
    value: loan.purpose
      ? capitalise(
          loan.purpose.split(/\\/).pop() || /* istanbul ignore next */ ""
        )
      : "",
  },
  {
    description: "Loan term",
    value: loan.loanTerm?.toString() ? `${+loan.loanTerm / 12} years` : "",
  },
];
