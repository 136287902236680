import { Action, Button, ButtonRow, Field, Icon, Modal } from "@wisr/react-ui";
import React, { useState } from "react";
import { addressToString } from "../../forms/credit-profile/credit-profile-form.utils";
import { RequestPayoutData } from "../../forms/request-payout/request-payout-form.format";
import { analyticsClickEvent } from "../../shared/analytics/analytics.utils";
import { isNotEmpty } from "../../shared/common.utils";
import { appInsights } from "../../shared/insights/app-insights";
import {
  EXCEPTION_ERROR_EVENT,
  USER_ERROR,
} from "../../shared/insights/app-insights.consts";
import { createAppInsightsEvent } from "../../shared/insights/app-insights.utils";
import { PORTFOLIO_API } from "../../shared/url.consts";
import { CreditUserProfile } from "../../user/credit/credit-user";
import { UserMetaData } from "../../user/user";
import { ButtonDownloadFile } from "../../widgets/shared/download/button/file-button-download.component";
import style from "./loan-statements.scss";
import { useFlags } from "launchdarkly-react-client-sdk";
import { PersonalLoan } from "../../types/personal-loan";

interface LoanStatementProps {
  user: UserMetaData;
  customer: CreditUserProfile;
  loan: PersonalLoan;
}

const ENTIRE_PERIOD_LABEL = "Entire Loan Period";
const ENTIRE_PERIOD_OPTION_VALUE = "entireLoanPeriod";
type ENTIRE_PERIOD_OPTION_TYPE = typeof ENTIRE_PERIOD_OPTION_VALUE;
type OPTION_TYPE = "3" | "6" | "12" | ENTIRE_PERIOD_OPTION_TYPE;

export const LoanStatements: React.FC<LoanStatementProps> = ({
  user,
  customer,
  loan,
}) => {
  const { showEntirePeriodTransactionsOption } = useFlags();
  const downloadOptions: Array<OPTION_TYPE> = ["3", "6", "12"];
  const [openModal, setOpenModal] = useState(false);
  const [downloadData, setDownloadData] = useState<
    RequestPayoutData | undefined
  >(undefined);
  const firstRadioOption: OPTION_TYPE = downloadOptions[0];
  const [radioVal, setRadioVal] = useState(firstRadioOption);

  React.useEffect(() => {
    if (isNotEmpty(user) && loan.loanId && customer.currentAddress) {
      const { currentAddress, title } = customer;
      const data = {
        loanId: loan.loanId,
        firstName: user.info?.firstName ?? "",
        lastName: user.info?.lastName ?? "",
        address: addressToString(currentAddress),
        title: title,
      };
      setDownloadData(data);
      // This will simplified when we clean up user on initialisation
      if (!user.info?.firstName || !user.info.lastName) {
        appInsights.trackEvent(
          EXCEPTION_ERROR_EVENT,
          createAppInsightsEvent({
            category: USER_ERROR,
            info: "User info is missing in personal loan widget",
            type: "User info missing",
          })
        );
      }
    }
  }, [user, loan, customer]);

  const downloadOptionObjects = downloadOptions.map((option) => ({
    value: option,
    label: `${option} months`,
  }));

  const statementOptions = showEntirePeriodTransactionsOption
    ? downloadOptionObjects.concat({
        value: ENTIRE_PERIOD_OPTION_VALUE,
        label: ENTIRE_PERIOD_LABEL,
      })
    : downloadOptionObjects;

  return (
    <div className="loan-statement">
      <style jsx>{style}</style>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        titleText="Download loan statement"
        size="medium"
      >
        <Field
          label="How long would you like your statement to cover?"
          type="radio"
          placeholder="How long would you like your statement to cover?"
          options={statementOptions}
          value={radioVal}
          onChange={({ currentTarget }) => {
            setRadioVal(currentTarget.value as OPTION_TYPE);
          }}
        />
        {downloadData && (
          <ButtonRow forForm>
            <Button button="primary">
              <ButtonDownloadFile
                apiLink={`${PORTFOLIO_API}/Customer/loans/transactions/document`}
                data={{
                  ...downloadData,
                  statementMonths:
                    radioVal === ENTIRE_PERIOD_OPTION_VALUE
                      ? undefined
                      : radioVal,
                  fileDisplayName: `Wisr-Transactions-${loan.loanId}-${
                    radioVal === ENTIRE_PERIOD_OPTION_VALUE
                      ? "entire-loan-period"
                      : radioVal
                  }-months.pdf`,
                }}
                customText
                type="POST"
              >
                DOWNLOAD
              </ButtonDownloadFile>
            </Button>
          </ButtonRow>
        )}
      </Modal>
      <div className="grid">
        <div className="item item-details">
          <span className="title">Statements</span>
        </div>
        <div className={`item download`}>
          <Action size="small">
            <button
              type="button"
              onClick={() => {
                setOpenModal(!openModal);
                analyticsClickEvent("Download statement");
              }}
            >
              <Icon name="action-download" size="sm" legacy={false} /> Download
            </button>
          </Action>{" "}
        </div>
      </div>
    </div>
  );
};
