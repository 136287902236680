// Move this to @Wisr/common-js
export const downloadFile = (
  url: string,
  customFilename?: string,
  file?: any
) => {
  try {
    const linkElement = document.createElement("a");
    const fileName = customFilename ? customFilename : getFileName(url);
    //Download attributes only work for same origin
    linkElement.setAttribute("download", fileName);
    linkElement.setAttribute(
      "href",
      file
        ? window.URL.createObjectURL(
            new Blob([file], { type: "application/pdf" })
          )
        : url
    );
    linkElement.setAttribute("target", "_blank");
    linkElement.click();
  } catch (error) {
    return Promise.reject(error);
  }
  return Promise.resolve();
};

export const getFileName = (filePath: string) => {
  return filePath.split("/").pop() || "download";
};
