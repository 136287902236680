import { classFormat } from "@wisr/common";
import React from "react";
import highlightStyle from "./list-highlight.scss";

export interface HighlightListItem {
  value: string;
  description: string;
}

interface HighlightProps {
  list: HighlightListItem[];
}

export const HighlightListComponent: React.FunctionComponent<
  HighlightProps
> = ({ list }) => {
  return (
    <dl>
      <style jsx>{highlightStyle}</style>
      {list.map((highlight, index) => {
        return (
          <div
            className={`grid ${classFormat(highlight.description)}`}
            key={index}
            id={classFormat(highlight.description)}
          >
            <dd>{highlight.description}</dd>
            <dt>{highlight.value}</dt>
          </div>
        );
      })}
    </dl>
  );
};
